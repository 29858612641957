<template>
  <div class="info">
    <div v-show="visible">
      <top-bar :title="'提议详情'" :left="true"></top-bar>
      <!-- 提议对象 -->
      <van-popup v-model="objectShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.objectList" value-key="label"
                    @confirm="objectConfirm" @cancel="objectShow = false"/>
      </van-popup>
      <!-- 提议时间 -->
      <van-popup v-model="proposalTimeShow" position="bottom">
        <van-datetime-picker v-model="submitData.proposalTime" type="datetime" title="请选择" :formatter="formatDate"
                             @cancel="proposalTimeShow = false" @confirm="timeConfirm"/>
      </van-popup>
      <!-- 回复时间 -->
      <van-popup v-model="replyTimeShow" position="bottom">
        <van-datetime-picker v-model="submitData.replyTime" type="datetime" title="请选择" :formatter="formatDate"
                             @cancel="replyTimeShow = false" @confirm="replyTimeConfirm"/>
      </van-popup>
      <!-- 所在小区 -->
      <van-popup v-model="subareaShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.subareaList" value-key="name"
                    @confirm="subareaConfirm" @cancel="subareaShow = false"/>
      </van-popup>

      <div class="splitLine"></div>
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">咨询</span>
          </template>
        </van-cell>
        <van-cell title="提议对象" :value="selectMsg.objectTypeStr"/>
        <van-cell title="提议标题" :value="submitData.title"/>
        <van-cell title="提议者" :value="submitData.proposer"/>
<!--        <van-cell title="所在小区" :value="selectMsg.subareaStr"/>-->
        <van-cell title="提议时间" :value="selectMsg.proposalTime"/>
        <van-cell-group>
          <van-cell title="提议内容" :border="false">
          </van-cell>
          <van-cell :border="false">
            <van-field class="content" v-model="submitData.proposeContent" rows="4" autosize type="textarea" placeholder="这是一条提议内容"
                       readonly/>
          </van-cell>
          <van-cell v-if="edit||fileList.length!==0">
            <van-uploader v-model="fileList" :deletable="false" :show-upload="false"/>
          </van-cell>
        </van-cell-group>
      </van-cell-group>
      <div class="splitLine"></div>
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">回复提议</span>
          </template>
        </van-cell>
        <van-cell>
          <van-field name="radio" label="是否采纳议题">
            <template #input>
              <van-radio-group v-model="submitData.adopt" direction="horizontal" :disabled="!edit">
                <van-radio name="0">不采纳</van-radio>
                <van-radio name="1">采纳</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </van-cell>
        <van-cell title="回复人" v-if="submitData.checkStatus === '3'">
          <van-field v-model="submitData.answer" placeholder="请输入" :readonly="!edit"/>
        </van-cell>
        <van-cell title="回复时间" :value="selectMsg.replyTime" @click="replyTimeShow = edit" v-if="submitData.checkStatus === '3'"/>
        <van-cell-group>
          <van-cell title="回复内容" :border="false">
          </van-cell>
          <van-cell :border="false">
            <van-field class="content" v-model="submitData.replyContent" rows="4" autosize type="textarea" placeholder="这是一条提议内容"
                       :readonly="!edit"/>
          </van-cell>
        </van-cell-group>
        <van-row class="" v-if="this.submitData.checkStatus === '0'">
          <van-col :span="24">
            <van-button type="info" size="large" :loading="loading" round @click="submit">提交回复</van-button>
          </van-col>
        </van-row>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDateTimeWithOutSec} from "@/utils/utils"
import {getImageStream} from '@/utils/index'

export default {
  data() {
    return {
      visible: true,
      edit: false,
      file: {},
      loading: false,
      objectShow: false,
      proposalTimeShow: false,
      subareaShow: false,
      replyTimeShow: false,
      selectMsg: {
        objectTypeStr: '请选择',
        subareaStr: '请选择',
        proposalTime: '请选择',
        replyTime: '请选择'
      },
      submitData: {
        id: '',
        checkStatus: 0,
        objectType: '',
        subarea: '',
        proposalTime: '',
        replyTime: '',
        title: '',
        proposer: '',
        proposeContent: '',
        adopt: '0',
        answer: '',
        replyContent: '',
        newFileMessages: [],
        deleteFiles: []
      },
      selectData: {
        objectList: [{label: "楼幢", value: "1"}, {label: "场所", value: "2"}, {label: "公共区域", value: "3"}],
        buildingTypeList: [{label: "住宅楼幢", value: "1"}, {label: "公共楼幢", value: "2"}, {label: "工业楼幢", value: "3"}],
        buildingList: [],
        publicBuildingList: [],
        subareaList: [],
        inspectionTypeList: [],
        problemList: []
      },
      fileList: [],
    }
  },
  components: {
    topBar
  },
  methods: {
    // 初始化
    init() {
      this.getSubareaList()
      let nowDate = new Date()
      this.maxDate = nowDate
      this.selectMsg.inspectionTime = formatterDateTimeWithOutSec(nowDate)
      this.submitData.inspectionTime = nowDate
      this.getInfo()
      if (this.submitData.checkStatus === '0') {
        this.edit = true
      } else {
        this.edit = false
      }
    },
    // 获取小区列表
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.subareaList = data.subAreaList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/future/letter/info/' + this.submitData.id),
        method: 'post'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectMsg.objectTypeStr = data.futureLetter.proposedTargetStr
          this.submitData.title = data.futureLetter.letterTitle
          this.submitData.proposer = data.futureLetter.name
          this.selectMsg.subareaStr = data.futureLetter.subareaStr
          this.selectMsg.proposalTime = data.futureLetter.createTime
          this.submitData.proposeContent = data.futureLetter.letterContent
          this.submitData.newFileMessages = data.futureLetter.fileMessages
          if (this.submitData.newFileMessages) {
            let files = this.submitData.newFileMessages
            let urls = []
            for (let i in files) {
              let file = files[i]
              let u
              u = getImageStream(files[i].relativePath)
              let url = {
                uid: urls.length,
                id: file.id,
                status: 'done',//uploading上传中，done上传完成，error上传失败
                fileType: file.fileType,
                url: u,
                realUrl: file.relativePath.replace(/\\/g, "/")
              }
              urls.push(url);
            }
            this.fileList = urls
          }
          if (this.submitData.checkStatus === '3') {
            this.submitData.adopt = data.futureLetter.adopt.toString()
            this.submitData.answer = data.futureLetter.checkName
            this.submitData.replyTime = data.futureLetter.checkTime
            this.selectMsg.replyTime = data.futureLetter.checkTime
            this.submitData.replyContent = data.futureLetter.checkContent
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 提交
    submit() {
      if (this.submitData.replyContent === '') {
        return this.$toast.fail('失败:回复内容不能为空');
      }
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/future/letter/letterCheck`),
        method: 'post',
        data: {
          id: this.submitData.id,
          adopt: Number(this.submitData.adopt),
          checkStatus: 3,
          checkContent: this.submitData.replyContent
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: '回复成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 提议对象选择
    objectConfirm(value) {
      if (value) {
        this.selectMsg.objectTypeStr = value.label
        this.submitData.objectType = value.value
      }
      this.objectShow = false
    },
    // 所在小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subareaStr = value.name
        this.submitData.subarea = value.id
      }
      this.subareaShow = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.proposalTime = formatterDateTimeWithOutSec(value)
        this.submitData.proposalTime = formatterDateTimeWithOutSec(value)
      }
      this.proposalTimeShow = false
    },
    replyTimeConfirm(value) {
      if (value) {
        this.selectMsg.replyTime = formatterDateTimeWithOutSec(value)
        this.submitData.replyTime = formatterDateTimeWithOutSec(value)
      }
      this.replyTimeShow = false
    }
  },
  created() {
    this.submitData.id = this.$route.query.id || ''
    this.submitData.checkStatus = this.$route.query.checkStatus
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style>
a {
  color: rgb(4, 50, 255);
}

.blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
</style>
<style lang="scss" scoped>
::v-deep .van-field__control {
  text-align: right;
}

.btns {
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  padding: 0 20px;
  margin-top: 30px;
}

.van-divider {
  border-color: #000;
}

.info {
  margin-bottom: 150px;
}

.van-field {
  padding: 0;
}
.content {
  border-radius: 10px;
  padding: 20px;
  background-color: #F5F5F5;
  ::v-deep .van-field__control {
    text-align: left !important;
  }
  ::v-deep .van-cell::after {
    border-bottom: none;
  }
}
.splitLine {
  height: 20px;
  width: 100%;
  background-color: #F5F5F5;
}
::v-deep .van-radio-group--horizontal{
  margin-left: 185px;
}
</style>
